<template>
  <div>
    <topbar
      :titulo="$t('ACADEMICO.FORMULARIO_ADITIVO')"
      :subtitulo="$t('ACADEMICO.FORMULARIO_ADITIVO_TURMA_DESCRICAO')"
      :breadcrumbs="breadcrumbs"
    >
      <template #header>
        <div
          class="d-flex flex-column flex-md-row justify-content-between align-items-md-center gap-2 ml-md-4"
        >
          <div></div>
          <b-button
            variant="secondary"
            class="mr-2"
            @click="retornarParaAditivos"
          >
            {{ $t('GERAL.VOLTAR') }}
          </b-button>
        </div>
      </template>
    </topbar>

    <b-tabs v-model="tabIndex" card>
      <b-tab :title="`1 - ${$t('ACADEMICO.CONTRATOS_TURMA')}`">
        <b-row>
          <b-col cols="12">
            <span class="d-block mt-2 mb-4">{{
              $t('ACADEMICO.DESCRICAO_FORMULARIO_CONTRATOS_TURMA_ADITIVO')
            }}</span>

            <hr />

            <filtro-contratos-por-turma
              :form="filtro"
              @limparFiltro="limparFiltro"
              @filtrar="getContratosPorTurma"
            />
            <hr />

            <tabela-contratos-turma
              :form="form"
              :itens="opcoes.contratos"
              :turma="opcoes.turma"
            />
          </b-col>
        </b-row>
      </b-tab>
      <b-tab :title="`2 - ${$t('ACADEMICO.DADOS_GERAIS_DO_ADITIVO')}`">
        <b-row>
          <b-col cols="12">
            <input-select-search
              ref="tiposAditivo"
              :label="$t(`ACADEMICO.TIPO_ADITIVO`)"
              :options="opcoes.tipoAditivos"
              v-model="form.tipoAditivoContrato"
              required
            />
          </b-col>

          <b-col cols="12">
            <input-editor-text
              ref="motivo"
              :label="$t(`ACADEMICO.DETALHES_DO_MOTIVO`)"
              :value="form.motivo"
              v-model="form.motivo"
            />
          </b-col>
        </b-row>
      </b-tab>
      <b-tab :title="`3 - ${$t('ACADEMICO.PRECOS')}`">
        <b-row>
          <b-col cols="12">
            <tabela-precos :form="form" @atualizou-preco="recarregarParcelas" />
          </b-col>

          <b-col cols="12">
            <tabela-valores-plano-parcelamento :itens="planoParcelamentos" />
          </b-col>
        </b-row>
      </b-tab>
      <b-tab :title="`4 - ${$t('ACADEMICO.DESCONTOS')}`">
        <tabela-descontos :form="form" />
        <div class="d-flex justify-content-end">
          <b-button variant="primary" @click="salvarAditivo()">{{
            $t('ACADEMICO.SALVAR_ADITIVO')
          }}</b-button>
        </div>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
// AUX & HELPERS
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';
import helpers from '@/common/utils/helpers';
import rotas from '@/common/utils/rotas';

// SERVICES
import PrecoService from '@/common/services/precos/preco.service';
import ContratosService from '@/common/services/contratos/contratos.service';
import EnumeradoresService from '@/common/services/enumeradores/enumeradores.service';
import TurmaService from '@/common/services/academico/turma.service';

// COMPONENTS
import { InputSelectSearch, InputEditorText } from '@/components/inputs';
import Topbar from '@/layouts/full-layout/horizontal-sidebar/Topbar.vue';
import TabelaPrecos from '../components/tabelas/TabelaPrecosAdicionados';
import TabelaValoresPlanoParcelamento from '../components/tabelas/TabelaValoresPlanoParcelamento';
import TabelaDescontos from '../../aditivo-contratos/components/tabelas/TabelaDescontosAdicionados';
import TabelaContratosTurma from './components/tabelas/TabelaContratosTurma';
import FiltroContratosPorTurma from './components/filtros/FiltroContratosPorTurma';
export default {
  name: 'FormularioCriarAditivo',

  props: {
    exibir: { type: Boolean, default: false },
  },

  components: {
    Topbar,
    InputSelectSearch,
    InputEditorText,
    TabelaPrecos,
    TabelaValoresPlanoParcelamento,
    TabelaDescontos,
    TabelaContratosTurma,
    FiltroContratosPorTurma,
  },

  data() {
    return {
      opcoes: {
        tipoAditivos: [],
        contratos: [],
        turma: '',
      },
      planoParcelamentos: [],

      form: {
        contratos: [],
        tipoAditivoContrato: '',
        precos: [],
        descontos: [],
        motivo: '',
        dataGeracao: new Date(),
      },
      filtro: {
        anoLetivoId: '',
        cursoId: '',
        grupoTurmaId: '',
        turmaId: '',
      },

      tabIndex: 0,

      breadcrumbs: [
        { titulo: this.$t('ACADEMICO_HOME.PAINEL_ACADEMICO') },
        { titulo: this.$t('ACADEMICO.ADITIVOS_DE_CONTRATO') },
        { titulo: this.$t('ACADEMICO.FORMULARIO_ADITIVO') },
      ],
    };
  },
  mounted() {
    this.pegarTiposAditivo();
  },
  methods: {
    salvarAditivo() {
      // validar informações
      if (!this.validarFormulario() || this.form.contratos.length === 0)
        return mensagem.warning(
          this.$t('GERAL.AVISO'),
          this.$t('ACADEMICO.PREENCHA_OS_CAMPOS_OBRIGATORIOS')
        );
      const descontosId = this.form.descontos.map((desconto) => desconto.id);
      const formFormatado = { ...this.form, descontos: descontosId };
      this.$store.dispatch(START_LOADING);
      ContratosService.adicionarAditivoContratos(formFormatado)
        .then(({ data }) => {
          mensagem.sucesso(this.$t('GERAL.SUCESSO'), this.$t(data.mensagem));
          this.form = {
            contratos: [],
            tipoAditivoContrato: '',
            precos: [],
            descontos: [],
            motivo: '',
            dataGeracao: new Date(),
          };
          this.limparFiltro();
          this.retornarParaAditivos();
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },

    //FUNÇÕES PARA PEGAR DADOS
    pegarTiposAditivo() {
      this.$store.dispatch(START_LOADING);
      EnumeradoresService.listar('tipos-aditivos-contrato')
        .then(({ data }) => {
          data = data.map((row) => ({
            ...row,
            value: row.id,
            text: row.descricao,
          }));
          this.opcoes.tipoAditivos = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },

    recarregarParcelas() {
      this.getPlanosParcelamento(this.form.precos);
    },

    getPlanosParcelamento(listaPrecosIds) {
      this.$store.dispatch(START_LOADING);
      PrecoService.buscarPlanosPagamento(listaPrecosIds)
        .then(({ data }) => {
          this.planoParcelamentos = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },

    getContratosPorTurma(filtro) {
      this.$store.dispatch(START_LOADING);
      TurmaService.listarContratoPorTurmaId(filtro.turmaId)
        .then(({ data }) => {
          this.opcoes.turma = data.turmaDescricao;
          this.opcoes.contratos = data.alunosEnturmados;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },

    // FUNÇÕES AUX
    retornarParaAditivos() {
      rotas.irPara('aditivos', this.$route.params.id);
    },

    validarFormulario() {
      return helpers.validarFormulario(this.$refs);
    },

    // FUNCOES FILTRO
    limparFiltro() {
      this.opcoes.turma = '';
      this.opcoes.contratos = [];

      this.filtro = {
        anoLetivoId: '',
        cursoId: '',
        grupoTurmaId: '',
        turmaId: '',
      };
    },
  },
  watch: {
    'form.precos': {
      handler(precos) {
        const precoIds = precos.map((item) => ({
          precoId: item.id,
          formaPagamentoId: item.formaPagamentoId,
        }));
        if (precoIds.length > 0) return this.getPlanosParcelamento(precoIds);
        this.planoParcelamentos = [];
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
