<template>
  <div>
    <div class="d-flex justify-content-between mb-2">
      <h4>{{ $t('ACADEMICO.SELECIONE_CONTRATOS') }}</h4>

      <div>
        <input-checkbox
          :label="$t('ACADEMICO.SELECIONAR_TODOS')"
          v-model="todosSelecionados"
          @change="selecaoTodos"
        />
      </div>
    </div>
    <b-table
      ref="selectableTable"
      :fields="campos"
      :items="itens"
      @row-selected="adicionar"
      :empty-text="$t('GERAL.NENHUM_REGISTRO')"
      head-variant="light"
      select-mode="multi"
      responsive="sm"
      show-empty
      selectable
      striped
    >
      <template #cell(turma)>
        {{ turma }}
      </template>
      <template #cell(selected)="{ rowSelected }">
        <template v-if="rowSelected">
          <i class="blue text-md fas fa-check-square d-flex"></i>
          <span class="sr-only">Selected</span>
        </template>
        <template v-else>
          <i class="text-md far fa-square d-flex"></i>
          <span class="sr-only">Not selected</span>
        </template>
      </template>
    </b-table>
  </div>
</template>

<script>
//components
import { InputCheckbox } from '@/components/inputs';

export default {
  name: 'ModalAdicionarDesconto',
  components: {
    InputCheckbox,
  },
  props: {
    form: { type: Object, default: Object },
    exibir: { type: Boolean, default: true },
    itens: { type: Array, default: Array },
    turma: { type: String },
  },
  data() {
    return {
      campos: [
        {
          key: 'codigoContrato',
          label: this.$t('ACADEMICO.CODIGO'),
        },
        {
          key: 'turma',
          label: this.$t('ACADEMICO.TURMA'),
        },
        {
          key: 'nome',
          label: this.$t('ACADEMICO.ALUNO'),
        },
        {
          key: 'selected',
          label: this.$t('GERAL.SELECIONADO'),
        },
      ],
      todosSelecionados: false,
    };
  },

  computed: {
    titulo() {
      return !this.item
        ? this.$t('PRECOS.ADICIONAR_DESCONTO')
        : this.$t('GERAL.EDITAR');
    },
  },
  watch: {
    exibir(novoValor) {
      if (novoValor) {
        this.filtrarDescontos();
      }
    },
  },

  methods: {
    adicionar(items) {
      const contratosId = items.map((item) => item.contratoId);
      this.form.contratos = contratosId;
    },

    selecaoTodos() {
      if (this.todosSelecionados)
        return this.$refs.selectableTable.selectAllRows();
      return this.$refs.selectableTable.clearSelected();
    },
  },
};
</script>

<style scoped>
.blue {
  color: blue;
}
.text-md {
  font-size: 1.2rem;
}
</style>
