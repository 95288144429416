<template>
  <div>
    <b-row>
      <b-col cols="12" md="3">
        <input-select
          :disabled="desabilitar.form"
          ref="form.anoLetivoId"
          :options="opcoes.anosLetivos"
          v-model="form.anoLetivoId"
          :label="$t('GERAL.ANO_LETIVO')"
          valueField="id"
          textField="ano"
          required
        />
      </b-col>
      <b-col cols="12" md="3">
        <input-select-search
          :isDisabled="desabilitar.curso"
          ref="form.cursoId"
          :options="opcoes.cursos"
          v-model="form.cursoId"
          :label="$t('GERAL.CURSO')"
          required
        />
      </b-col>
      <b-col cols="12" md="3">
        <input-select
          ref="form.grupoTurmaId"
          :options="opcoes.grupoTurmas"
          v-model="form.grupoTurmaId"
          :label="$t('GERAL.GRUPO_TURMA')"
          valueField="id"
          textField="descricao"
          :disabled="desabilitar.grupoTurma"
          required
        />
      </b-col>
      <b-col cols="12" md="3">
        <input-select
          ref="form.turmaId"
          :options="opcoes.turmas"
          v-model="form.turmaId"
          :label="$t('GERAL.TURMA')"
          valueField="id"
          textField="descricao"
          :disabled="desabilitar.turma"
          required
        />
      </b-col>
    </b-row>
    <b-row>
      <div class="col-12 text-right">
        <b-button variant="primary" @click="filtrar">
          {{ $t('ACADEMICO.LISTAR_CONTRATOS') }}
        </b-button>
      </div>
    </b-row>
  </div>
</template>

<script>
// Aux & helpes
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';

//servives
import AnoLetivoService from '@/common/services/anoLetivo/ano-letivo.service';
import GrupoTurmaService from '@/common/services/grupoTurma/grupo-turma.service';
import CursoService from '@/common/services/academico/curso.service';
import TurmaService from '@/common/services/academico/turma.service';

// Components:
import { InputSelect, InputSelectSearch } from '@/components/inputs';

export default {
  name: 'FiltroContratosPorTurma',

  props: {
    form: { type: Object, default: Object },
  },

  components: {
    InputSelect,
    InputSelectSearch,
  },
  data() {
    return {
      desabilitar: {
        form: false,
        curso: true,
        grupoTurma: true,
        turma: true,
      },
      opcoes: {
        anosLetivos: [],
        cursos: [],
        grupoTurmas: [],
        turmas: [],
      },
    };
  },
  mounted() {
    this.getCursos();
    this.getAnosLetivo();
  },
  methods: {
    filtrar() {
      this.$emit('filtrar', this.form);
    },

    limparFiltro() {
      this.form = {
        anoLetivoId: '',
        cursoId: '',
        grupoTurmaId: '',
      };
      this.$emit('limparFiltro');
    },

    //obter dados

    getAnosLetivo() {
      this.$store.dispatch(START_LOADING);
      AnoLetivoService.listar(this.paginacao)
        .then(({ data }) => {
          this.opcoes.anosLetivos = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },

    getCursos() {
      if (this.form.anoLetivoId.length === 0) {
        return;
      }
      this.$store.dispatch(START_LOADING);
      CursoService.obterPorAnoLetivo(this.form.anoLetivoId)
        .then(({ data }) => {
          data = data.map((row) => ({
            ...row,
            text: row.descricao,
            value: row.id,
          }));
          this.opcoes.cursos = data;
          if (!this.id) {
            this.desabilitar.curso = false;
          }
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },

    getGrupoTurma() {
      if (this.form.anoLetivoId.length == 0 || this.form.cursoId.length == 0) {
        return;
      }
      this.$store.dispatch(START_LOADING);
      GrupoTurmaService.buscarPorCursoIdAnoLetivoId(
        this.form.cursoId,
        this.form.anoLetivoId
      )
        .then(({ data }) => {
          data = data.map((row) => ({
            ...row,
            text: row.descricao,
            value: row.id,
          }));
          this.opcoes.grupoTurmas = data;
          if (!this.id) {
            this.desabilitar.grupoTurma = false;
          }
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },

    getTurmas() {
      if (this.form.cursoId.length == 0 || this.form.grupoTurmaId.length == 0) {
        return;
      }
      this.$store.dispatch(START_LOADING);
      TurmaService.obterTurmaPorGrupoTurma(this.form)
        .then(({ data }) => {
          data = data.map((row) => ({
            ...row,
            text: row.descricao,
            value: row.id,
          }));
          this.opcoes.turmas = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },

    // funcoes aux
    habilitarInputs() {
      if (this.form.anoLetivoId) {
        this.desabilitar.curso = false;
      } else {
        this.desabilitar.curso = true;
      }
      if (this.form.cursoId.length > 0) {
        this.desabilitar.grupoTurma = false;
      } else {
        this.desabilitar.grupoTurma = true;
      }
      if (this.form.grupoTurmaId.length > 0) {
        this.desabilitar.turma = false;
      } else {
        this.desabilitar.turma = true;
      }
    },
  },
  watch: {
    'form.anoLetivoId': {
      handler: 'getCursos',
      immediate: true,
    },
    'form.cursoId': {
      handler(novoValor, valorAntigo) {
        if (novoValor !== valorAntigo) {
          this.getGrupoTurma();
          this.form.grupoTurmaId = '';
          this.opcoes.turmas = [];
          this.desabilitar.turma = true;
        }
    },
      immediate: true,
    },
    'form.grupoTurmaId': {
      handler: 'getTurmas',
      immediate: true,
    },
    form: {
      handler: 'habilitarInputs',
      deep: true,
    },
  },
};
</script>
